import { Route, Routes, Navigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";

import Home from './components/Home';
import Contact from './components/Contact';

import Blog from './components/Blog';
import StockPage from "./components/StockPage";
import OldLink from './components/subcomponents/OldLink';
import Guide from "./components/Guide.jsx";

import Buildings from "./components/Buildings.jsx";
import Library from './components/Library';
import MarketUpgrades from './components/MarketUpgrades';

import Dream from './components/Dream.jsx';
import DreamBuildings from './components/DreamBuildings.jsx';
import DreamQuests from './components/DreamQuest.jsx';
// import DreamMarket from './components/DreamMarket.jsx';

import ThemeRedirect from './components/ThemeRedirect';       
import ThemeEvent from "./components/ThemeEvent";

import News from './components/News';

import Fishing from './components/Fishing';
import Bees from './components/Bees';
import Cafe from './components/Cafe';
import SiteMenu from './components/SiteMenu';
import Tree from './components/Tree';

import SeasonalEvents from './components/SeasonalEvents';

import Oops from './components/subcomponents/Oops';

import CoopProject from './components/CoopProject';
import CoopUpgrade from './components/CoopUpgrade';
import CoopVillage from './components/CoopVillage';
import CoopLights from './components/CoopLights';

import ComingUp from './components/ComingUp';
import Cookies from './components/subcomponents/Cookie';

// import Products from './components/Products';            //  This one



// // import GuidePage from "./components/GuidePage";
// // import BlogPage from "./components/BlogPage";
// import Support from "./components/Support";

// // import Game from './components/Game';
// // import ProductValues from './components/Costs';
// import NewsPage from './components/NewsPage';

// import CreateBlog from "./components/CreateBlog";
// import CreateGuide from "./components/CreateGuide";

function App() {
  return(
    <div className = "App">
      <Cookies />
      <Header />
      <Navbar />
      <Routes>
        
        <Route path="/" element = {<Home />} />
        <Route path="/contact" element = {<Contact />} />

        <Route path = "/blogs" element = {<Navigate to ="/blog" replace />} />
        <Route path = "/blog" >
          <Route index element = {<Blog/>} />
          <Route path = ":pageName" element = {<StockPage/>} />
        </Route>

        <Route path = "/post/*" element = {<OldLink />}/>
        
        <Route path = "/guide" element = {<Navigate to = "/guides" replace />} />
        <Route path="/guides">
          <Route index element = {<Guide />} />
          <Route path=":pageName" element = {<StockPage />} />
        </Route>

        <Route path = "/land" element = {<Navigate to = "/buildings/land" replace />} />
        <Route path = "/building" element = {<Navigate to = "/buildings/house" replace />} />
        <Route path = "/buildings" element = {<Navigate to = "/buildings/house" replace />} />
        <Route path = "/buildings/:buildingUrl" element = {<Buildings />}/>
        
        <Route path = "/library" element = {<Navigate to = "/library/wildflower" replace />} />
        <Route path = "/library/:libraryUrl" element = {<Library />} />

        <Route path = "/market/upgrade" element = {<Navigate to = "/market/upgrades/contract" replace />} />
        <Route path = "/market/upgrades" element = {<Navigate to = "/market/upgrades/contract" replace />} />
        <Route path = "/market/upgrades/:marketUrl" element = {<MarketUpgrades />} />

        <Route path = "/event" element = {<Navigate to = "/themes" replace />} />
        <Route path = "/theme" element = {<Navigate to = "/themes" replace />} />
        <Route path = "/themes" element = {<ThemeRedirect />} />
        <Route path = "/themes/:themeUrl" element = {<ThemeEvent />} />

        <Route path = "/news" element = {<News archive = {true}/>} />
        {/* <Route path = "/news/:newsUrl" component = {NewsPage}/> This is the old version as well.  Need to update to use useParams()*/}

        <Route path="/bees" element = {<Bees />} />
        {/* <Route path="/support" element = {<Contact />} /> */}
        <Route path="/fishing" element = {<Fishing />} />
        <Route path="/cafe" element = {<Cafe />} />
        <Route path="/menu" element = {<SiteMenu />} />

        {/* <Route path="/products" element = {<Products />} />             */}
        
        <Route path = "/tree" element = {<Navigate to = "/tree-of-life" replace />} />
        <Route path="/tree-of-life" element = {<Tree />} />

        <Route path = "/seasonal/:eventUrl" element = {<SeasonalEvents/>} />
        <Route path = "/seasonal" element = {<SeasonalEvents />} />

        <Route path = "/co-op">
          <Route index element = {<Navigate to = "/co-op/projects" replace />} />
          <Route path = "upgrades" element = {<Navigate to = "/co-op/upgrades/funds" replace />} />
          <Route path="projects" element = {<CoopProject />} />
          <Route path="village" element = {<CoopVillage />} />
          <Route path="upgrades/:upgradeUrl" element = {<CoopUpgrade />} />
          <Route path="lights" element = {<CoopLights />} />
        </Route>

        <Route path = "/dream">
          <Route index element = {<Dream/>}/>
          <Route path = "quests" element = {<DreamQuests />}/>
          
          {/* <Route path = "market" element = {<DreamMarket />}/> */}
          <Route path = "buildings/:buildingUrl" element = {<DreamBuildings />}/>
        </Route>

        {/* <Route path = "/dream">
          <Route index element = {<h1>Dream</h1>} />
          <Route path = "buildings" element = {<Navigate to = "/dream/buildings/dream-house"/>} />
          <Route path = "/buildings/:buildingUrl" element = {<DreamBuildings />}/>
        </Route> */}

        <Route path="/coming-up" element = {<ComingUp />} />

        <Route path="/:pageName" element = {<StockPage />} />

        <Route path = "*" element = {<Oops text = "That page doesn't seem to exist...  Check the link and try again.  I'll take you back to the home page" link="/"/>} />
        
      </Routes>
      <Footer />
    </div>
  
  );
    
    
}  
    
    
//     <Router>
//     <div className="App">
//       {/* {!localStorage.getItem('_CookieNotification') && <Cookies/>} */}
//                 
//         {/* <Route path="/costs" component = {Costs} />
//         <Route exact path="/guides/costs" component = {Costs} /> */} still in development

   
//         {/* <Route path="/cblog" component = {CreateBlog} />
//         <Route path="/cguide" component = {CreateGuide} /> */}
//         {/* <Route path="/settings" component = {CreateBlog} /> */}
//       </Switch>
//       <Footer />
//     </div>
//     </Router>
    
//   );
// }

export default App;
